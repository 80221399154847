
import React, { Fragment } from 'react'
import {
    List,
    Datagrid,
    Edit,
    Create,
    SimpleForm,
    DateField,
    TextField,
    ArrayField,
    SingleFieldList,
    EditButton,
    TextInput,
    DateInput,
    Responsive,
    SimpleList,
    SimpleShowLayout,
    ShowButton,
    Show,
    UrlField,
    SelectInput,
    Filter,
    useListContext,
    TopToolbar,
    CreateButton,
    ExportButton,
    Pagination,
    Button,
    ChipField,
    required,
    sanitizeListRestProps,
    ReferenceField,
    SaveButton,
    DeleteButton,
    Toolbar,
    ArrayInput,
    SimpleFormIterator,
    NumberInput,
    ReferenceInput,
    ReferenceArrayInput,
    SelectArrayInput,
    ImageField,
    BulkDeleteButton,
} from 'react-admin'
import { cloneElement, useMemo } from 'react'
import PropTypes from 'prop-types'
import BookIcon from '@material-ui/icons/ShoppingCart'
import ShortUrlField from '../Customs/ShortUrlField'
import MultipleField from '../Customs/MultipleField'
import MultipleInput from '../Customs/MultipleInput'
import DeleteAllButton from '../Customs/DeleteAllButton'
import ImportButton from '../Customs/ImportButton'
import ApiSelectInput from '../Customs/ApiSelectInput'

const API_URL = process.env.REACT_APP_API_HOSTNAME

export const CommandIcon = BookIcon


const Command_Filter = (props) => (
    <Filter {...props}>
        <TextInput source="ref" />
        <TextInput source="ip" />
        <TextInput source="status" />
        <TextInput source="note" />
        <ReferenceInput source="user" reference="User" >
            <SelectInput optionText="email" />
        </ReferenceInput>
    </Filter>
)

const ListActions_Store = {}

const ListActions = (props) => {
    const {
        className,
        exporter,
        filters,
        maxResults,
        ...rest
    } = props
    let {
        currentSort,
        resource,
        displayedFilters,
        filterValues,
        hasCreate,
        basePath,
        selectedIds,
        showFilter,
        total,
    } = useListContext()
    ListActions_Store.filterValues = filterValues
    return (
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
            {filters && cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: 'button',
            })}
        </TopToolbar>
    )
}

const BulkActionButtons = props => (
    <Fragment>
        <BulkDeleteButton {...props} />
    </Fragment>
);

export const CommandList = (props) => (
    <List {...props} filters={<Command_Filter />} actions={<ListActions />} perPage={10} sort={{ field: 'id', order: 'DESC' }} bulkActionButtons={BulkActionButtons} >
        <Responsive
            small={
                <SimpleList linkType="show"
                    primaryText={record => record ? record.ref : ''}
                />
            }
            medium={
                <Datagrid>
                    <TextField source="ref" sortable={false} />
                    <TextField source="ip" sortable={false} />
                    <TextField source="status" sortable={false} />
                    <TextField source="amount" sortable={true} />
                    <TextField source="product_id" sortable={true} />
                    <ReferenceField source="user" reference="User" >
                        <TextField source="email" />
                    </ReferenceField>
                    <DateField source="updatedAt" showTime={true} sortable={true} />
                    <ShowButton />
                    <EditButton />
                </Datagrid>
            }
        />
    </List>
)

export const CommandShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="id" />
            <TextField source="ref" />
            <TextField source="ip" />
            <TextField source="status" />
            <TextField source="amount" />
            <TextField source="stripe_customer_id" />
            <TextField source="stripe_session_id" />
            <TextField source="note" component="pre" />
            <ReferenceField source="user" reference="User" >
                <TextField source="email" />
            </ReferenceField>
            <DateField source="createdAt" showTime={true} />
            <DateField source="updatedAt" showTime={true} />
        </SimpleShowLayout>
    </Show>
)

const CustomToolBar = props => <Toolbar {...props} ><SaveButton /></Toolbar>

export const CommandEdit = (props) => (
    <Edit {...props} undoable={false} >
        <SimpleForm redirect="show" toolbar={<CustomToolBar />}>
            <TextInput source="status" validate={required()} />
            <TextInput source="note" multiline fullWidth />
        </SimpleForm>
    </Edit>
)
