import * as React from 'react';
import { createElement, useState } from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from '@material-ui/core';
import { MenuItemLink, getResources, useTranslate } from 'react-admin';
import { withRouter } from 'react-router-dom';
import LabelIcon from '@material-ui/icons/Label';
import DashboardIcon from '@material-ui/icons/Dashboard';
import EventIcon from '@material-ui/icons/Event';
import MailIcon from '@material-ui/icons/Mail';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import Divider from '@material-ui/core/Divider';
import MenuItem from '@material-ui/core/MenuItem';

const Menu = (setDashboard) => {

    const A = function ({ onMenuClick, logout }) {
        const [close, setClose] = useState({});
        const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
        const open = useSelector(state => state.admin.ui.sidebarOpen);
        const resources = useSelector(getResources);
        const translate = useTranslate()
        let previous
        return (
            <div style={{
                marginTop: "24px",
                marginBottom: "100px"
            }}>
                <MenuItemLink
                    key="Dashboard"
                    to={`/`}
                    primaryText={translate('ra.customs.dashboard_title')}
                    leftIcon={<DashboardIcon />}
                    onClick={(e) => {
                        setDashboard("Main")
                        onMenuClick(e)
                    }}
                />
                <MenuItemLink
                    key="Graph"
                    to={`/graph`}
                    primaryText={"Graph"}
                    leftIcon={<EqualizerIcon />}
                    onClick={(e) => {
                        onMenuClick(e)
                    }}
                />
                {(() => {
                    const items = []
                    resources.forEach((resource, index) => {
                        if (previous !== resource.options.group && resource.options.group) {
                            items.push(<Divider />)
                            if (open)
                                items.push((
                                    <MenuItem
                                        key={resource.options.group}
                                        onClick={() => {
                                            close[resource.options.group] = !close[resource.options.group]
                                            setClose({ ...close })
                                        }}
                                        style={{ fontWeight: "bold", margin: "10px" }}
                                    >
                                        {resource.options.group}
                                        {/* {translate(`ra.customs.menu_${resource.options.group}`)} */}
                                    </MenuItem>
                                ))
                        }
                        previous = resource.options.group
                        if (!close[resource.options.group] && resource.icon) {
                            items.push(
                                <MenuItemLink
                                    key={resource.name}
                                    to={`/${resource.name}`}
                                    primaryText={resource.options && resource.options.label ||
                                        (translate(`resources.${resource.name}.name`, 2) !== `resources.${resource.name}.name` ?
                                            translate(`resources.${resource.name}.name`, 2) : resource.name)}
                                    leftIcon={createElement(resource.icon)}
                                    onClick={onMenuClick}
                                    sidebarIsOpen={open}
                                />
                            )
                        }
                    })
                    return items
                })()
                }
                {isXSmall && logout}
            </div>
        );
    }

    return withRouter(A)
}

export default Menu;