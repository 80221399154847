
// in app.js
import * as React from "react"
import { Admin, Resource, DeleteButton } from 'react-admin'
import dataProvider from './dataProvider'
import authProvider from './authProvider';
import { i18nProvider as polyglotI18nProvider } from './polyglotProvider'
import Dashboard from './Dashboard'
import Theme from './Theme'
import LoginPage from './pages/LoginPage'
import Graph from './pages/Graph'
import Menu from './Menu'

import { UserList, UserShow, UserEdit, UserIcon } from './resources/user'
import { CommandList, CommandShow, CommandEdit, CommandIcon } from './resources/command'
import { TrackingList, TrackingShow, TrackingIcon } from './resources/tracking'

import { BrowserRouter as Router, Route } from "react-router-dom";


import { Provider } from 'react-redux';
import { createHashHistory } from 'history';
import createAdminStore from './createAdminStore';

const history = createHashHistory();


class App extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      dashboard: null
    }
  }

  render() {
    return (
      <Provider
        store={createAdminStore({
          authProvider,
          dataProvider,
          history,
        })}
      >
        <Admin
          theme={Theme}
          menu={Menu((dashboard) => this.setState({ dashboard }))}
          dataProvider={dataProvider}
          authProvider={authProvider}
          dashboard={Dashboard(this.state.dashboard)}
          i18nProvider={polyglotI18nProvider}
          loginPage={LoginPage}
          history={history}
          customRoutes={[
            <Route exact path="/graph" component={Graph} />,
          ]}
        >
          {/* <Resource name="Preuser" options={{ group: "users" }} list={PreuserList} show={PreuserShow} icon={PreuserIcon} remove={DeleteButton} /> */}
          <Resource name="User" options={{ group: "users" }} list={UserList} show={UserShow} edit={UserEdit} icon={UserIcon} />
          <Resource name="Command" options={{ group: "commands" }} list={CommandList} show={CommandShow} edit={CommandEdit} icon={CommandIcon} />
          <Resource name="Tracking" options={{ group: "tracking" }} list={TrackingList} show={TrackingShow} icon={TrackingIcon} remove={DeleteButton} />
        </Admin>
      </Provider>
    )
  }
}

export default App
